<template>
  <router-view />
</template>

<script>
export default {
  name: "AuthLayout",
  created() {},
  data: () => ({}),
};
</script>
