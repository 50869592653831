const LogLevel = {
	DEBUG: "debug",
	VERBOSE: "verbose",
	INFO: "info",
	WARN: "warn",
	ERROR: "error",
	SILENT: "silent",
};

const ConsoleColor = {
	[LogLevel.DEBUG]: "#9E9E9E",
	[LogLevel.VERBOSE]: "#9E9E9E",
	[LogLevel.INFO]: "#03A9F4",
	[LogLevel.WARN]: "#FFC107",
	[LogLevel.ERROR]: "#FF5252",
};

export default class Logger {
	static log(level, ...value) {
		const now = new Date().toISOString();
		const heading = `[${now}] %c${level.toUpperCase()}:`;
		const styles = `color: ${ConsoleColor[level]}; font-weight: bold;`;

		console[level](heading, styles, ...value);
	}

	static debug(...value) {
		this.log(LogLevel.DEBUG, ...value);
	}

	static verbose(...value) {
		this.log(LogLevel.VERBOSE, ...value);
	}

	static info(...value) {
		this.log(LogLevel.INFO, ...value);
	}

	static warn(...value) {
		this.log(LogLevel.WARN, ...value);
	}

	static error(...value) {
		this.log(LogLevel.ERROR, ...value);
	}
}
