import AuthLayout from '@/layouts/auth/AuthLayout.vue';
import { isSessionValid } from '@/lib/session-helper';

const routes = [
  {
    path: 'auth/',
    name: 'auth',
    meta: {
      requiresAuth: false
    },
    redirect: {
      name: 'login'
    },
    beforeEnter: (to, from, next) => {
      const { sessionValid } = isSessionValid();
      if (sessionValid) {
        return next(`/app/reports`);
      }
      return next();
    },
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: 'login',

        component: () => import('@/views/auth/LoginPage.vue')
      },
      {
        path: 'forgot/password/:token',
        name: 'forgot-password',

        component: () =>
          import('@/views/auth/ForgetPasswordTokenVerificationPage.vue')
      }
    ]
  }
];

export default routes;
