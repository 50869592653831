import Logger from './logger';
import camelCase from 'lodash/camelCase';

/**
 * Autoloads root vuex modules into an object.
 * Each modules are mapped in the object as the camelCased file name without the extension
 * For example:
 *  If the filename for the store module is app.module.js
 *  Then the module name is "appModule"
 *  Hence the mapping is
 *  {
 *    appModule: {
 *        namespaced: true,
 *        state: {},
 *        getters: {},
 *        mutations: {},
 *        actions:{}
 *    }
 *  }
 *  This therefore can be used throught the project as follows:
 *  import { mapGetters } from "vuex";
 *  export default {
 *    ...
 *    computed: {
 *       ...mapGetters("appModule", ["the name of the getter here"])
 *    }
 *    ...
 *  }
 *
 * @returns { Object } modules
 */
const loadRootStore = () => {
  const modules = {};
  const requireModule = require.context('@/store/modules', true, /\.js$/);

  requireModule.keys().forEach(path => {
    if (path.indexOf('.module.js') > -1) {
      const moduleName = camelCase(
        [...path.split('/')].pop().replace(/(\.\/|\.js)/g, '')
      );
      try {
        Logger.info('Loaded Store Module: ', moduleName);
        const store = requireModule(path);
        modules[moduleName] = {
          namespaced: true,
          ...store
        };
      } catch (e) {
        Logger.error('Unable to load root store module: ', moduleName, e);
      }
    }
  });

  return modules;
};

/**
 * Autoloads vuex modules from each application modules into an object.
 * Each modules are mapped in the object as the camelCased module namw and file name without the extension
 * For example:
 *  If the file path for the store module in the module is @/modules/loan-pro/store/loan.module.js
 *  Then the module name will be "loanProLoanModule"
 *  Hence the mapping is
 *  {
 *    loanProLoanModule: {
 *        namespaced: true,
 *        state: {},
 *        getters: {},
 *        mutations: {},
 *        actions:{}
 *    }
 *  }
 *  This therefore can be used throught the project as follows:
 *  import { mapGetters } from "vuex";
 *  export default {
 *    ...
 *    computed: {
 *       ...mapGetters("loanProLoanModule", ["the name of the getter here"])
 *    }
 *    ...
 *  }
 *
 * @returns { Object } modules
 */
// const loadModuleStores = () => {
//   const modules = [];
//   const requireModule = require.context('@/modules', true, /\.module.js$/);
//   requireModule.keys().map(async path => {
//     if (path.indexOf('.module.js') > -1) {
//       let moduleName = camelCase(path.replace(/(\.\/|\.js)/g, ''));
//       moduleName = moduleName.replace(/Store/g, '');
//       try {
//         const store = requireModule(path);
//         Logger.info('Loaded Store Module: ', moduleName);
//         modules[moduleName] = {
//           namespaced: true,
//           ...store
//         };
//       } catch (e) {
//         Logger.error('Unable to load Store Module: ', moduleName, e);
//       }
//     }
//   });

//   return modules;
// };

/**
 * Autoloads the routes from the routes.js file of each application modules into an array.
 *
 * @returns { Array } routes
 */
// const loadModuleRoutes = () => {
//   const routes = [];
//   const requireModule = require.context('@/modules', true);
//   requireModule.keys().map(async path => {
//     if (path.indexOf('routes.js') > -1) {
//       try {
//         const route = requireModule(path);
//         routes.push(route.default);
//         Logger.info('Loaded Routes: ', route);
//       } catch (e) {
//         Logger.error('Unable to load Routes: ', path.replace('./', ''), e);
//       }
//     }
//   });

//   return routes;
// };

const rootStore = loadRootStore();
// const moduleStore = loadModuleStores();
// const moduleRoutes = loadModuleRoutes();

export { rootStore };
