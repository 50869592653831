import { Http } from '../../lib/http';

export class UserService extends Http {
  constructor() {
    super();
    this.endpoint = 'users/';
  }

  batchSave(users) {
    return this.httpClient().post(`${this.endpoint}/batch`, users);
  }

  create(user) {
    return this.httpClient().post(`${this.endpoint}`, user);
  }

  findOne(id) {
    return this.httpClient().get(`${this.endpoint}${id}`);
  }

  findAll(filter = '', sort = '', { skip = 0, limit = -1 }) {
    let query = `${sort}`;
    query += limit < 1 ? '' : `&skip=${skip}&limit=${limit}`;
    return this.httpClient().get(`${this.endpoint}${filter}${query}`);
  }

  update(id, user) {
    return this.httpClient().put(`${this.endpoint}${id}`, user);
  }

  generateNewPassword(id) {
    return this.httpClient().patch(
      `${this.endpoint}${id}/generate-new-password`
    );
  }

  logout(data) {
    return this.httpClient().post(`${this.endpoint}log-time`, data);
  }

  delete(id) {
    return this.httpClient().delete(`${this.endpoint}${id}`);
  }
}
