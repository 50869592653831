import { Http } from '../../lib/http';

export class AuthService extends Http {
  constructor() {
    super();
  }

  signIn(email, password) {
    return this.httpClient().post(`/auth/log-in`, {
      email,
      password
    });
  }

  signOut(id) {
    return this.httpClient().post(`/auth/log-out/${id}`, {});
  }

  changePassword(id, newPassword) {
    return this.httpClient().patch(`users/${id}/change-password`, {
      password: newPassword
    });
  }

  requestPasswordChange(email) {
    return this.httpClient().post(`auth/${email}/request-password-change`);
  }

  verifyForgottenPasswordToken(token) {
    return this.httpClient().patch(`auth/confirm-password-change/${token}`);
  }
}
