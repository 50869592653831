import { isSessionValid } from '../../lib/session-helper';

export default (to, from, next) => {
  const { sessionValid, sessionExpired } = isSessionValid();
  if (!sessionValid) {
    return next(`/auth/login${sessionExpired ? '?session=false' : ''}`);
  }

  next();
};
