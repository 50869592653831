import Vue from 'vue';
import VueRouter from 'vue-router';

import AuthRoutes from './auth.routes';
import AppRoutes from './app.routes';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'base',
    component: () => import('@/layouts/CoreLayout.vue'),
    redirect: { name: 'auth' },
    children: [...AuthRoutes, ...AppRoutes]
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/shared/404.vue'),
    meta: { label: '404' }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
