import { UserService } from '@/services/api/user.service';
import { make } from 'vuex-pathify';
import Logger from '@/lib/logger';

const userService = new UserService();

export const state = {
  users: [],
  nonUsers: [],
  user: {}
};

export const mutations = {
  ...make.mutations(state),

  ADD_USER: (state, payload) => {
    state.users.push(payload);
  },

  UPDATE_USER: (state, { id, payload }) => {
    const index = state.users.findIndex(el => el._id == id);
    state.users.splice(index, 1, payload);
  },

  DELETE_USER: (state, id) => {
    const index = state.users.findIndex(el => el._id == id);
    state.users.splice(index, 1);
  }
};

export const actions = {
  loadUserPage: async ({ dispatch }) => {
    try {
      const modules = ['userModule/fetchUsers'];

      for (const module of modules) {
        await dispatch(module, null, { root: true });
      }
    } catch (error) {
      Logger.error('loading user page');
      throw error;
    }
  },

  fetchUsers: async ({ commit }) => {
    try {
      const { data } = await userService.findAll('', '', {});
      commit('SET_USERS', data.results);
      return data.results;
    } catch (error) {
      Logger.error('fetching users');
      throw error;
    }
  },

  fetchUser: async ({ commit }, id) => {
    try {
      const { data } = await userService.findOne(id);
      commit('SET_USER', data);
      return data;
    } catch (error) {
      Logger.error('fetching user');
      throw error;
    }
  },

  createUser: async ({ commit }, payload) => {
    try {
      const { data } = await userService.create(payload);

      payload = data;
      payload.isActive = true;
      commit('ADD_USER', payload);
      return data;
    } catch (error) {
      Logger.error('create user');
      throw error;
    }
  },

  updateUser: async ({ commit }, { id, payload }) => {
    try {
      const req = { ...payload };
      const { data } = await userService.update(id, req);
      console.log(data, id);
      commit('UPDATE_USER', { id, payload: data });
    } catch (error) {
      Logger.error('updating user');
      throw error;
    }
  },

  deleteUser: async ({ commit }, id) => {
    try {
      await userService.delete(id);
      commit('DELETE_USER', id);
    } catch (error) {
      Logger.error('updating user');
      throw error;
    }
  },

  generatePassword: async ({ dispatch }, id) => {
    try {
      await userService.generateNewPassword(id);
      await dispatch('userModule/fetchNonUsers', null, { root: true });
    } catch (error) {
      Logger.error('generate new user');
      throw error;
    }
  }
};

export const getters = make.getters(state);
