import { ReportService } from '@/services/api/report.service';
import { make } from 'vuex-pathify';
import Logger from '@/lib/logger';
import moment from 'moment';

const reportService = new ReportService();

export const state = {
  reports: [],
  report: {},
  accounts: []
};

export const mutations = {
  ...make.mutations(state),

  ADD_REPORT: (state, payload) => {
    state.reports.push(payload);
  },

  UPDATE_REPORT: (state, { id, payload }) => {
    const index = state.reports.findIndex(el => el._id == id);
    state.reports.splice(index, 1, payload);
  },

  DELETE_REPORT: (state, id) => {
    const index = state.reports.findIndex(
      el => el.reportId == id && el.mostCurrent
    );
    state.reports.splice(index, 1);
  },

  ADD_ACCOUNT: (state, payload) => {
    state.accounts.push(payload);
  },

  DELETE_ACCOUNT: (state, id) => {
    const index = state.accounts.findIndex(el => el.id == id);
    state.accounts.splice(index, 1);
  },

  UPDATE_ACCOUNT: (state, { id, payload }) => {
    const index = state.accounts.findIndex(el => el._id == id);
    state.accounts.splice(index, 1, payload);
  }
};

export const actions = {
  fetchReports: async ({ commit }, institutionId) => {
    try {
      let filter = '';
      if (institutionId) {
        filter = `?institution=${institutionId}&isDeleted=false`;
      }
      const { data } = await reportService.findAll(filter);
      commit('SET_REPORTS', data.results || []);
      return data.results;
    } catch (error) {
      Logger.error('fetching reports');
      throw error;
    }
  },

  findReport: ({ state }, id) => {
    return state.reports.find(el => el._id == id);
  },

  fetchReport: async ({ commit }, id) => {
    try {
      const { data } = await reportService.findOne(id);
      commit('SET_REPORT', data);
      return data;
    } catch (error) {
      Logger.error('fetching report');
      throw error;
    }
  },

  createReport: async ({ commit }, payload) => {
    try {
      const { data } = await reportService.create(payload);
      commit('ADD_REPORT', data);
      return data;
    } catch (error) {
      Logger.error('create report');
      throw error;
    }
  },

  updateReport: async ({ commit }, { id, payload }) => {
    try {
      payload.dateUpdated = moment().format();
      const { data } = await reportService.update(id, payload);
      commit('UPDATE_REPORT', { id, payload: data });
    } catch (error) {
      Logger.error('updating report');
      throw error;
    }
  },

  deleteReport: async ({ commit }, { reportId }) => {
    try {
      await reportService.delete(reportId);
      await reportService.deleteAccounts(reportId);
      commit('DELETE_REPORT', reportId);
    } catch (error) {
      Logger.error('updating report');
      throw error;
    }
  },

  /*eslint-disable no-empty-pattern */
  uploadAccounts: async (
    {},
    { type, reportId, formData, onUploadProgress }
  ) => {
    try {
      await reportService.uploadAccounts(
        formData,
        type,
        reportId,
        onUploadProgress
      );
    } catch (error) {
      Logger.error('uploading accounts');
      throw error;
    }
  },

  /*eslint-disable no-empty-pattern */
  findAccount: async ({}, id) => {
    try {
      const { data } = await reportService.findAccount(id);
      return data;
    } catch (error) {
      Logger.error('fetching account');
      throw error;
    }
  },

  createAccount: async ({ commit, dispatch }, item) => {
    try {
      const { data } = await reportService.createAccount({
        ...item,
        dateCreated: new Date()
      });

      await dispatch(
        'reportModule/fetchReport',
        item.report._id || item.report,
        {
          root: true
        }
      );

      commit('ADD_ACCOUNT', data);
    } catch (error) {
      Logger.error('creating account');
      throw error;
    }
  },

  updateAccount: async ({ commit, dispatch }, item) => {
    try {
      const { data } = await reportService.updateAccount(item._id, {
        ...item,
        dateUpdated: new Date()
      });

      await dispatch(
        'reportModule/fetchReport',
        item.report._id || item.report,
        {
          root: true
        }
      );

      commit('UPDATE_ACCOUNT', { id: item._id, payload: data });
    } catch (error) {
      Logger.error('updating account');
      throw error;
    }
  },

  deleteAccount: async ({ commit, dispatch }, item) => {
    try {
      const { data } = await reportService.deleteAccount(item._id, {
        ...item,
        dateUpdated: new Date()
      });

      await dispatch(
        'reportModule/fetchReport',
        item.report._id || item.report,
        {
          root: true
        }
      );

      if (!data) {
        commit('DELETE_REPORT', item._id);
      } else {
        commit('UPDATE_ACCOUNT', { id: item._id, payload: data });
      }
    } catch (error) {
      Logger.error('delete account');
      throw error;
    }
  },

  findAccountsByReportId: async ({ commit }, reportId) => {
    try {
      const { data } = await reportService.findAccountsByReportId(reportId);
      commit('SET_ACCOUNTS', data.results);
    } catch (error) {
      Logger.error('fetching accounts');
      throw error;
    }
  },

  /*eslint-disable no-empty-pattern */
  downloadXMLReport: async ({ state }, id) => {
    try {
      const report = state.reports.find(el => el._id == id);
      const { messageTypeIndicator, documentType } = report;

      let docType = `nil${report.isTestData ? '_test_' : ''}`;
      if (messageTypeIndicator === 'CRS702') {
        docType = `correction${
          ['OECD10', 'OECD12', 'OECD13'].includes(documentType) ? '_test_' : ''
        }`;
      } else if (messageTypeIndicator === 'CRS701') {
        docType = `new${['OECD11'].includes(documentType) ? '_test_' : ''}`;
      }

      const fileName = `crs_${docType}_${report.reportingPeriodEndDate}.xml`;

      const { data } = await reportService.downloadXMLReport(id);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      Logger.error('download xml report');
      throw error;
    }
  },

  /*eslint-disable no-empty-pattern */
  generateUniqueReportID({}, { report, user }) {
    const { country, taxIdentificationNumber } = user.institution;
    const year = moment(report.reportingPeriodEndDate).format('YYYY');
    const recipientCountry = report.recipientCountry;
    const uniqueNumber = Math.round(new Date().valueOf() / 1000);
    return `${country}${year}${recipientCountry}${taxIdentificationNumber}${uniqueNumber}`;
  },

  generateUniqueMessageID({}, { report, user }) {
    const { country, taxIdentificationNumber } = user.institution;
    const year = moment(report.reportingPeriodEndDate).format('YYYY');
    const recipientCountry = report.recipientCountry;
    const uniqueNumber = Math.round(new Date().valueOf() / 1000);

    if (report.isDomestic) {
      return `${taxIdentificationNumber}${country}${year}${uniqueNumber}`;
    }

    return `${country}${year}${recipientCountry}${taxIdentificationNumber}${uniqueNumber}`;
  },

  /*eslint-disable no-empty-pattern */
  generateUniqueAccountID({}, { account, user, reportMongoId }) {
    const institution = user.institution;
    return `${institution.country}${account.accountNumber}-${reportMongoId}`;
  }
};

export const getters = {
  ...make.getters(state),
  mostCurrentReports: state => state.reports.filter(el => el.mostCurrent),
  mostCurrentAccounts: state => state.accounts.filter(el => el.mostCurrent)
};
