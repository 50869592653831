import { Http } from '../../lib/http';

export class ReportService extends Http {
  constructor() {
    super();
    this.endpoint = 'reports/';
    this.accountEndpoint = 'accounts/';
  }

  create(report) {
    return this.httpClient().post(`${this.endpoint}`, report);
  }

  downloadXMLReport(id) {
    return this.httpClient().post(
      `${this.accountEndpoint}${id}/download`,
      {
        timestamp: new Date()
      },
      { responseType: 'blob' }
    );
  }

  findOne(id) {
    return this.httpClient().get(`${this.endpoint}${id}`);
  }

  findAll(filter = '', sort = '', paginate = { skip: 0, limit: -1 }) {
    let query = `${sort}`;
    query +=
      paginate.limit < 1
        ? ''
        : `&skip=${paginate.skip}&limit=${paginate.limit}`;
    return this.httpClient().get(`${this.endpoint}${filter}${query}`);
  }

  update(id, report) {
    return this.httpClient().patch(`${this.endpoint}${id}`, report);
  }

  delete(id) {
    return this.httpClient().delete(`${this.endpoint}${id}`);
  }

  uploadAccounts(formData, type, reportId, onUploadProgress) {
    return this.httpClient().post(
      `${this.accountEndpoint}upload/${type}/report/${reportId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress
      }
    );
  }

  findAccountsByReportId(reportId) {
    return this.httpClient().get(
      `${this.accountEndpoint}?reportId=${reportId}`
    );
  }

  findAccount(accountId) {
    return this.httpClient().get(`${this.accountEndpoint}${accountId}`);
  }

  createAccount(account) {
    return this.httpClient().post(
      `${this.accountEndpoint}${account.accountType}`,
      account
    );
  }

  updateAccount(id, account) {
    return this.httpClient().put(
      `${this.accountEndpoint}${id}/${account.accountType}`,
      account
    );
  }

  deleteAccount(id, account) {
    return this.httpClient().patch(
      `${this.accountEndpoint}${id}/${account.accountType}`,
      account
    );
  }

  deleteAccounts(id) {
    return this.httpClient().delete(`${this.accountEndpoint}${id}`);
  }
}
