import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { registerPlugins } from './plugins';
import './plugins/vuetify-money';


// styles
import './styles/style.css';
Vue.config.productionTip = false;

registerPlugins(Vue);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
