import { AuthService } from '@/services/api/auth.service';
import { pick } from '../../lib/helpers';
import Logger from '@/lib/logger';
import TokenStorage from '@/lib/token-storage';

const authService = new AuthService();

export const state = {
  refreshToken: '',
  accessToken: '',
  status: '',
  error: '',
  user: {}
};

export const mutations = {
  SET_REDIRECT_URL(state, payload) {
    state.redirectURL = payload;
  },

  SET_STATUS(state, payload) {
    state.status = payload;
  },

  SET_ERROR(state, payload) {
    state.error = payload;
  },

  SET_USER(state, payload) {
    state.user = payload;
  },

  SET_TOKEN(state, { token }) {
    state.accessToken = token;
    if (token) {
      TokenStorage.setAccessToken(token);
    } else {
      TokenStorage.deleteAccessToken();
    }
  },

  CLEAR_TOKEN(state) {
    state.accessToken = '';
    TokenStorage.deleteAccessToken();
  },

  CLEAR_USER(STATE) {
    STATE.user = null;
  }
};

export const actions = {
  clearErrors: ({ commit }) => {
    commit('SET_ERROR', '');
    commit('SET_STATUS', '');
  },

  setRedirectURL: ({ commit }, payload) => {
    commit('SET_REDIRECT_URL', payload);
  },

  signIn: async ({ commit }, { email, password }) => {
    try {
      commit('SET_ERROR', '');

      const result = await authService.signIn(email, password);
      if (!result) {
        commit('SET_STATUS', 'failed');
        commit('SET_ERROR', 'Incorrect username or password.');
        return;
      }

      commit('SET_STATUS', result.status || 'failed');
      commit('SET_TOKEN', pick(result.data, ['token']));
      commit('SET_USER', result.data.user);
    } catch (e) {
      if (e.response && e.response.status >= 400 && e.response.status < 500) {
        commit('SET_ERROR', e.response.data.message);
        return;
      }
      commit('SET_ERROR', 'Something went wrong');
      Logger.error('sign in failed');
      throw e;
    }
  },

  signOut: async ({ commit }, email) => {
    try {
      await authService.signOut(email);
    } catch (e) {
      Logger.error('sign out failed');
      throw e;
    }

    commit('SET_STATUS', 'Not Authenticated');
    commit('CLEAR_TOKEN');
    commit('CLEAR_USER');
  },

  requestPasswordChange: async ({ commit }, email) => {
    try {
      await authService.requestPasswordChange(email);
      commit('CLEAR_USER');
    } catch (e) {
      Logger.error('request password change failed');
      throw e;
    }
  },

  /*eslint-disable no-empty-pattern */
  verifyPasswordResetToken: async ({}, token) => {
    try {
      await authService.verifyForgottenPasswordToken(token);
    } catch (e) {
      Logger.error('verify password change failed');
      throw e;
    }
  }
};

export const getters = {
  status: state => state.status,
  accessToken: state => state.accessToken,
  error: state => state.error,
  user: state => state.user,
  isAuthenticated: state => !!state.access_token // This should validated based on time - on computed time
};
