import axios from 'axios';
// import TokenStorage from './token-storage';
import { ROOT_API } from './constants/env';
import Logger from '@/lib/logger';

const defaultOptions = {
  baseURL: ROOT_API
};

class Http {
  _options;
  _instance;

  constructor(opts = defaultOptions) {
    this._options = opts;
    this._instance = axios.create({
      withCredentials: true,
      ...this._options
    });

    //Response interceptor
    this._instance.interceptors.response.use(
      function(response) {
        const { status, data } = response;
        return {
          status,
          data: data.data || data
        };
      },
      function(error) {
        if (error.response && error.response.status === 401) {
          if (window.location.href.indexOf('/auth') !== -1) {
            return;
          }

          // window.location.href = window.location.origin + '/auth';
          // return;
        }
        return Promise.reject(error);
      }
    );
  }

  httpClient() {
    return this._instance;
  }
}

const responseErrorHelper = error => {
  const { response, request } = error;
  if (response) {
    Logger.error('response error: ', response);
    const { status, data } = response;
    if (status == 409) {
      return `<strong>Conflict</strong>! ${data.message}`;
    }
  } else if (request) {
    Logger.info('network error: ', request);
    return '<strong>The network is abnormal</strong> please check your network';
  } else {
    Logger.info('unknown error: ', error);
    return '<strong>Unknown issue</strong>. Please try again.';
  }
};

export { Http, responseErrorHelper };
