<template>
  <ApplicationLayout
    :user="user"
    :options="options"
    :version="version"
    :links="links"
  >
    <!-- Set System bar content if enabled-->
    <template #systembar> </template>

    <!-- Set Appbar title-->
    <template #title>
      <v-toolbar-title class="font-weight-bold d-flex">
        CRS Reporting Tool
      </v-toolbar-title>
      <v-icon small style="margin-top: -20px">mdi-wrench-outline</v-icon>
    </template>

    <!-- Use to add addition items to Appbar -->
    <template #toolbar>
      <v-btn class="mr-2" :icon="isMobile" depressed to="/app/report">
        <v-icon>mdi-plus</v-icon> <span v-if="!isMobile">New Report</span>
      </v-btn>
    </template>

    <!-- Use change Navbar header content-->
    <template #navheader> </template>

    <!-- Use display content-->
    <template #content>
      <router-view />
    </template>
  </ApplicationLayout>
</template>

<script>
import ApplicationLayout from '@/components/core/layouts/ApplicationLayout.vue';
import CoreMixin from '@/mixins/core.mixin';
import appLinks from '@/router/data/appLinks.json';

const layoutOptions = {
  showSystemBar: false,
  showSearchBar: false,
  showUserOptions: true,
  showMenuToggle: true,

  appBarColor: '#fafbfc',
  miniDrawerColor: '',
  miniDrawerIconsColor: 'white',
  leftDrawerColor: 'white',
  leftDrawerIconsColor: ''
};

export default {
  components: { ApplicationLayout },
  mixins: [CoreMixin],
  data: () => ({
    options: layoutOptions,
    links: []
  }),
  mounted() {
    this.links = appLinks.filter((el) =>
      el.roles.some((el) => el === this.user.role)
    );
  }
};
</script>
