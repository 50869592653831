import { Http } from '../../lib/http';

export class InstitutionService extends Http {
  constructor() {
    super();
    this.endpoint = 'institutions/';
  }

  create(institution) {
    return this.httpClient().post(`${this.endpoint}`, institution);
  }

  findOne(id) {
    return this.httpClient().get(`${this.endpoint}${id}`);
  }

  findAll(filter = '', sort = '', paginate = { skip: 0, limit: -1 }) {
    let query = `${sort}`;
    query +=
      paginate.limit < 1
        ? ''
        : `&skip=${paginate.skip}&limit=${paginate.limit}`;
    return this.httpClient().get(`${this.endpoint}${filter}${query}`);
  }

  update(id, institution) {
    return this.httpClient().patch(`${this.endpoint}${id}`, institution);
  }

  delete(id) {
    return this.httpClient().delete(`${this.endpoint}${id}`);
  }
}
