import { InstitutionService } from '@/services/api/institution.service';
import { make } from 'vuex-pathify';
import Logger from '@/lib/logger';

const institutionService = new InstitutionService();

export const state = {
  institutions: [],
  institution: {}
};

export const mutations = {
  ...make.mutations(state),

  ADD_INSTITUTION: (state, payload) => {
    state.institutions.push(payload);
  },

  UPDATE_INSTITUTION: (state, { id, payload }) => {
    const index = state.institutions.findIndex(el => el._id == id);
    state.institutions.splice(index, 1, payload);
  },

  DELETE_INSTITUTION: (state, id) => {
    const index = state.institutions.findIndex(el => el._id == id);
    state.institutions.splice(index, 1);
  }
};

export const actions = {
  fetchInstitutions: async ({ commit }) => {
    try {
      const { data } = await institutionService.findAll();
      commit('SET_INSTITUTIONS', data.results || []);
      return data.results;
    } catch (error) {
      Logger.error('fetching institutions');
      throw error;
    }
  },

  findInstitution: ({ state }, id) => {
    return state.institutions.find(el => el._id == id);
  },

  fetchInstitution: async ({ commit }, id) => {
    try {
      const { data } = await institutionService.findOne(id);
      commit('SET_INSTITUTION', data);
      return data;
    } catch (error) {
      Logger.error('fetching institution');
      throw error;
    }
  },

  createInstitution: async ({ commit }, payload) => {
    try {
      const { data } = await institutionService.create(payload);
      commit('ADD_INSTITUTION', data);
      return data;
    } catch (error) {
      Logger.error('create institution');
      throw error;
    }
  },

  updateInstitution: async ({ commit }, { id, payload }) => {
    try {
      const { data } = await institutionService.update(id, payload);
      commit('UPDATE_INSTITUTION', { id, payload: data });
    } catch (error) {
      Logger.error('updating institution');
      throw error;
    }
  },

  deleteInstitution: async ({ commit }, id) => {
    try {
      await institutionService.delete(id);
      commit('DELETE_INSTITUTION', id);
    } catch (error) {
      Logger.error('updating institution');
      throw error;
    }
  }
};

export const getters = make.getters(state);
