import jwtDecode from 'jwt-decode';
import TokenStorage from '@/lib/token-storage';

const isSessionValid = () => {
  let sessionValid = true;
  let sessionExpired = false;
  const token = TokenStorage.getAccessToken();

  if (token) {
    const decodedToken = jwtDecode(token);

    if (decodedToken.exp < new Date().valueOf() / 1000) {
      sessionValid = false;
      sessionExpired = true;
    }
  } else {
    sessionValid = false;
  }
  return { sessionValid, sessionExpired };
};

export { isSessionValid };
