import SecureLS from 'secure-ls';

const ls = new SecureLS({ isCompression: false });

export default class TokenStorage {
  static getAccessToken() {
    return ls.get('accessToken');
  }

  static setAccessToken(token) {
    ls.set('accessToken', token);
  }

  static deleteAccessToken() {
    ls.remove('accessToken');
  }
}
