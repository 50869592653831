import AppLayout from '@/layouts/app/AppLayout.vue';
import authentication from './middlewares/authentication';
// import SettingsLayout from '@/layouts/app/SettingsLayout.vue';

let routes = [
  {
    path: 'app/',
    name: 'app',
    beforeEnter: authentication,
    meta: {
      requiresAuth: false
    },
    component: AppLayout,
    redirect: {
      name: 'central'
    },
    children: [
      {
        path: 'central',
        name: 'central',
        component: () => import('@/views/app/CentralPage.vue')
      },
      {
        path: 'report',
        name: 'report-form',
        component: () => import('@/views/app/reports/ReportFormPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'report/:id',
        name: 'report-form-edit',
        component: () => import('@/views/app/reports/ReportFormPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'reports/:id/accounts',
        name: 'reports-accounts',
        component: () => import('@/views/app/reports/ReportViewPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'reports/:reportId/account',
        name: 'account-form',
        component: () => import('@/views/app/reports/AccountFormPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'reports/:reportId/account/:accountId',
        name: 'account-form-edit',
        component: () => import('@/views/app/reports/AccountFormPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/views/app/reports/ReportPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'user-account',
        name: 'user-account',
        component: () => import('@/views/app/UserAccount.vue'),
        beforeEnter: authentication
      }
    ]
  },
  {
    path: 'settings/',
    name: 'settings',
    meta: {
      requiresAuth: false
    },
    beforeEnter: authentication,
    component: AppLayout,
    redirect: {
      name: 'institutions'
    },
    children: [
      {
        path: 'institutions',
        name: 'institutions',
        component: () => import('@/views/settings/InstitutionPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'institution',
        name: 'institution',
        component: () => import('@/views/settings/InstitutionFormPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'institution/:id',
        name: 'institution',
        component: () => import('@/views/settings/InstitutionFormPage.vue'),
        beforeEnter: authentication
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/settings/UsersPage.vue'),
        beforeEnter: authentication
      }
    ]
  }
];

export default routes;
