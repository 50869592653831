import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  mimes,
  required,
  email,
  alpha,
  alpha_spaces,
  alpha_num,
  double,
  length,
  confirmed,
  max,
  min,
  min_value,
  max_value,
  numeric,
  required_if
} from 'vee-validate/dist/rules';

import moment from 'moment';

extend('mimes', mimes);
extend('double', double);
extend('alpha', alpha);
extend('alpha_spaces', alpha_spaces);
extend('alpha_num', alpha_num);
extend('confirmed', confirmed);
extend('min_value', min_value);
extend('required', {
  ...required,
  message: 'This field is required'
});
extend('required_if', required_if);
extend('numeric', numeric);
extend('email', email);
extend('length', length);
extend('telephone', {
  validate(value) {
    return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(value);
  },
  message: 'Invalid phone number. Eg. (000) 000-0000'
});
extend('max_value', {
  ...max_value,
  message: '{_field_} may not be greater than {max}'
});

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
});
extend('min', {
  ...min,
  message: '{_field_} must be {length} characters or more'
});

extend('url', {
  validate(value) {
    const regex = new RegExp(
      '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
    );
    return regex.test(value);
  },
  message: 'Must be a valid url'
});

extend('verify_password', {
  validate(value) {
    let strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return strongRegex.test(value);
  },
  message:
    'The password must be 8 characters long and contains at least:   1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)'
});

extend('date', {
  validate: value => {
    const date = moment(value);
    return date.isValid();
  },
  message: 'Date is invalid'
});

extend('dob', {
  params: ['min'],
  validate(value, { min }) {
    const age = moment().diff(value, 'years', false);

    return Number(age) >= min;
  },
  message: 'You must be at least {min} to sign up'
});

extend('time_greater_than', {
  params: ['start'],
  validate(value, { start }) {
    const e = Number((value || '').replace(':', ''));
    const s = Number((start || '').replace(':', ''));
    return e > s;
  },
  message: 'Must be greater than {start}'
});

extend('time_less_than', {
  params: ['end'],
  validate(value, { end }) {
    const e = Number((end || '').replace(':', ''));
    const s = Number((value || '').replace(':', ''));
    return s < e;
  },
  message: 'Must be less than {end}'
});

extend('date_greater_than', {
  params: ['start'],
  validate(value, { start }) {
    const e = new Date(value);
    const s = new Date(start);
    return e > s;
  },
  message: 'Must be greater than {start}'
});

extend('year_less_than', {
  params: ['start'],
  validate(value, { start }) {
    const e = new Date(value);
    const s = new Date();

    s.setFullYear(start);
    return e.getFullYear() < s.getFullYear();
  },
  message: 'Must be less than year {start}'
});

extend('date_less_than', {
  params: ['end'],
  validate(value, { end }) {
    const e = new Date(end);
    const s = new Date(value);
    return s < e;
  },
  message: 'Must be less than end'
});

extend('number', {
  validate(value) {
    return !isNaN(value);
  },
  message: 'Must be a numeric value'
});

/**
 * @name registerValidationComponent
 * @link plugins/validator.ts
 * @param app `VueConstructor`
 *
 * loads and bootstraps validation form inputs
 */
export function registerValidationComponent(app) {
  app.component('ValidationProvider', ValidationProvider);
  app.component('ValidationObserver', ValidationObserver);
}
