<template>
  <v-app id="inspire">
    <v-system-bar v-if="options.showSystemBar" app>
      <slot name="systembar"></slot>
    </v-system-bar>

    <v-app-bar
      :color="options.appBarColor"
      class="shadow"
      elevate-on-scroll
      app
      clipped-right
      height="72"
    >
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mobile && options.showMenuToggle"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <slot name="title">
        <v-toolbar-title class="font-weight-bold d-flex">
          App Center
        </v-toolbar-title>
        <v-icon small style="margin-top: -20px">mdi-apps</v-icon>
      </slot>

      <v-spacer></v-spacer>

      <v-responsive
        v-if="!$vuetify.breakpoint.mobile && options.showSearchBar"
        max-width="156"
      >
        <v-text-field
          dense
          flat
          hide-details
          rounded
          placeholder="Search..."
          solo-inverted
        ></v-text-field>
      </v-responsive>

      <slot name="toolbar"></slot>

      <v-menu
        v-if="user"
        rounded="xs"
        :nudge-bottom="10"
        content-class="shadow-md"
        offset-y
        bottom
        left
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="options.showUserOptions"
            depressed
            rounded
            v-bind="attrs"
            v-on="on"
            class="grey--text text--darken-2"
          >
            <v-icon color="mr-1">mdi-account-circle-outline</v-icon>
            <span class="text-capitalize" v-if="!$vuetify.breakpoint.mobile">{{
              userFullName
            }}</span>
            <v-icon color=" ml-1 ">mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            three-line
            class="text-center align-center justify-center"
          >
            <div class="d-flex flex-column">
              <v-avatar
                size="70"
                class="grey lighten-3 text-center align-self-center"
              >
                <v-img v-if="user.avatar" :src="user.avatar"></v-img>
                <v-icon v-else size="40">mdi-account-outline</v-icon>
              </v-avatar>
              <span
                class="
                  text-capitalize
                  mt-2
                  subtitle-2
                  grey--text
                  text--darken-3
                  font-weight-light
                "
                >{{ userFullName }}</span
              >

              <span
                class="
                  text-capitalize
                  grey--text
                  text--darken-3
                  font-weight-light
                "
                ><b>{{ user.role || 'Admin' }}</b></span
              >
              <span class="caption font-weight-light">{{ user.email }}</span>
              <span class="caption font-weight-light" v-if="user.institution">{{
                user.institution.name | capitalize
              }}</span>
            </div>
          </v-list-item>

          <v-divider class="mt-3" />

          <v-list-item class="small-list-item" to="/app/user-account" link>
            <v-list-item-title
              class="grey--text text--darken-3 font-weight-light"
              >Manage your account settings</v-list-item-title
            >
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>

          <v-divider class="mb-1" />

          <v-list-item class="small-list-item" @click="logOut()">
            <v-list-item-title
              class="grey--text text--darken-3 font-weight-light"
              >Sign Out</v-list-item-title
            >
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn @click="toggleRightDrawer" v-if="showRightDrawer" icon>
        <v-icon>mdi-tune-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      hide-overlay
      color="grey lighten-5"
      v-model="drawer"
      :mini-variant="mini"
      app
      width="350"
    >
      <v-navigation-drawer
        :permanent="$vuetify.breakpoint.mobile && drawer"
        hide-overlay
        v-model="drawer"
        absolute
        color="primary darken-2"
        mini-variant
      >
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="d-block mx-auto mt-3 mb-1"
              icon
              large
              to="/app"
            >
              <v-icon class="mt-2" :color="options.miniDrawerIconsColor"
                >mdi-home-outline</v-icon
              >
            </v-btn>
          </template>
          <span>Apps</span>
        </v-tooltip>

        <v-divider class="mx-3 my-4"></v-divider>
        <template v-if="mini">
          <v-btn
            v-for="item in links"
            :key="item.heading || item.title"
            icon
            large
            class="d-block text-center mx-auto mb-1"
            @click="mini = !mini"
            ><v-icon color="white">{{ item.icon }}</v-icon></v-btn
          >
        </template>

        <template v-if="mini" #append>
          <div class="d-flex align-center justify-center">
            <v-btn icon @click="mini = !mini" x-large>
              <v-icon :color="options.miniDrawerIconsColor">
                {{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
              </v-icon>
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <template #prepend>
        <slot name="navheader">
          <v-sheet color="transparent">
            <v-toolbar color="transparent" style="padding-left: 40px" flat>
              <v-list-item three-line dense>
                <img
                  class="mt-1"
                  style="height: 36px"
                  src="../../../assets/logos/company.png"
                  alt=""
                />
                <v-spacer />
                <small class="font-weight-bold">
                  {{ `v${version || '1.0'} ` }}
                </small>
              </v-list-item>
            </v-toolbar>
          </v-sheet>
        </slot>
      </template>

      <v-list class="pl-14 pt-6" dense>
        <template v-for="item in links">
          <v-list-group
            v-if="item.heading"
            :key="item.heading"
            v-model="item.active"
            :prepend-icon="item.icon"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="grey--text text--darken-3">{{
                  item.heading
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              :to="child.to"
              :href="child.href"
              v-for="child in item.items"
              class="pl-8"
              :key="child.title"
              link
            >
              <v-list-item-icon class="pr-2 mr-2" v-if="child.icon">
                <v-icon>
                  {{ child.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content class="pl-0">
                <a
                  v-if="child.download"
                  style="text-decoration: none"
                  :href="child.download"
                  target="_blank"
                  download
                >
                  <v-list-item-title
                    class="grey--text pl-0 text--darken-2"
                    v-text="child.title"
                  ></v-list-item-title>
                </a>
                <v-list-item-title
                  v-else
                  class="grey--text pl-0 text--darken-2"
                  v-text="child.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-else :key="item.title" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <template #append>
        <div class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <v-btn
            class="mr-2"
            icon
            @click="
              $vuetify.breakpoint.mobile ? (drawer = !drawer) : (mini = !mini)
            "
            x-large
          >
            <v-icon>
              {{ mini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main style="background: #fafbfc">
      <slot name="content"></slot>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import { EventBus } from '@/event-bus.js';
const defaultOptions = {
  showSystemBar: false,
  showSearchBar: false,
  showUserOptions: true,
  showMenuToggle: true,

  appBarColor: '#fafbfc',
  miniDrawerColor: '',
  miniDrawerIconsColor: 'white',
  leftDrawerColor: 'white',
  leftDrawerIconsColor: ''
};

export default {
  name: 'ApplicationLayout',
  props: {
    options: {
      type: Object,
      default: () => defaultOptions
    },

    version: {
      type: String,
      default: () => '1.0'
    },
    user: {
      type: Object,
      default: () => {}
    },
    links: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    publicPath: process.env.BASE_URL,
    unWatchOptions: null,
    mini: true,
    drawer: null,
    showRightDrawer: false
  }),

  created() {
    //Watch for events
    EventBus.$on('SHOW_RIGHT_DRAWER', (event) => {
      this.showRightDrawer = event;
    });

    this.unWatchOptions = this.$watch(
      'options',
      (cur) => {
        for (const k in defaultOptions) {
          if (!cur[k] && typeof cur[k] !== 'boolean')
            cur[k] = defaultOptions[k];
        }

        //unwatch options
        if (this.unWatchOptions) this.unWatchOptions();
      },
      { immediate: true }
    );
  },

  computed: {
    userFullName() {
      return `${this.user?.name || 'Super User'}`;
    }
  },

  methods: {
    ...mapActions('authModule', ['signOut']),

    toggleRightDrawer() {
      EventBus.$emit('TOGGLE_RIGHT_DRAWER', true);
    },

    async logOut() {
      await this.signOut(this.user._id);
      this.$router.replace('/auth');
    }
  }
};
</script>

<style lang="sass" scoped>
.small-list-item
  max-height: 36px !important
  height: 36px !important
  font-size: 11px

.border
  border: 1px solid #ddd
</style>
