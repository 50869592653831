import { EventBus } from '../event-bus';
import { mapGetters } from 'vuex';
import { CORE_VERSION } from '@/lib/constants/env';
import { responseErrorHelper } from '@/lib/http';

export default {
  computed: {
    ...mapGetters('coreModule', ['apps']),
    ...mapGetters('authModule', ['user']),

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    version() {
      return CORE_VERSION;
    },

    isAdmin() {
      return !this.user?.institution || false;
    }
  },

  methods: {
    /**
     * This is used to display an snack alert to the user
     * @param {*} options
     */
    showSnack: options => {
      EventBus.$emit('SHOW_SNACK', options);
    },

    httpErrorHandler(error) {
      const err = responseErrorHelper(error);

      this.showSnack({
        color: 'error',
        title: `Something went wrong`,
        message: err
      });
    }
  }
};
