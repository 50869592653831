import { make } from 'vuex-pathify';
import Logger from '@/lib/logger';
import countriesAndSubDivisions from '@/assets/json/countriesAndSubDivisions.json';
import isoCurrencies from '@/assets/json/isoCurrencyCodes.json';

const ADDRESS_TYPES = [
  { code: 'OECD301', name: 'OECD301 - Residential or Business' },
  { code: 'OECD302', name: 'OECD302 - Residential' },
  { code: 'OECD303', name: 'OECD303 - Business' },
  { code: 'OECD304', name: 'OECD304 - Registered Office' },
  { code: 'OECD305', name: 'OECD305 - Unspecified' }
];

const MESSAGE_TYPE_INDICATORS = [
  { code: 'CRS701', name: 'CRS701 - New Information' },
  { code: 'CRS702', name: 'CRS702 - Correction' },
  { code: 'CRS703', name: 'CRS703 - No Data to report' }
];

const DOCUMENT_TYPES = [
  { code: 'OECD0', name: 'OECD0 - Resend Data' },
  { code: 'OECD1', name: 'OECD1 - New Data' },
  { code: 'OECD2', name: 'OECD2 - Corrected Data' },
  { code: 'OECD3', name: 'OECD3 - Delete Data' },

  { code: 'OECD10', name: 'OECD10 - Resent Test Data' },
  { code: 'OECD11', name: 'OECD11 - New Test Data' },
  { code: 'OECD12', name: 'OECD12 - Corrected Data' },
  { code: 'OECD13', name: 'OECD13 - Deletion of Test Data' }
];

const NAME_TYPES = [
  { code: 'OECD202', name: 'OECD202 - Individual' },
  { code: 'OECD203', name: 'OECD203 - Alias' },
  { code: 'OECD204', name: 'OECD204 - Nickname' },
  { code: 'OECD205', name: 'OECD205 - Also Known as' },
  { code: 'OECD206', name: 'OECD206 - Does Business as' },
  { code: 'OECD207', name: 'OECD207 - Legal Name' }
];

const ACCOUNT_HOLDER_TYPES = [
  {
    code: 'CRS101',
    name: `CRS101 - Passive Non-Financial Entity with – one or more controlling person that
is a Reportable Person`
  },
  { code: 'CRS102', name: 'CRS102 - CRS Reportable Person' },
  {
    code: 'CRS103',
    name:
      'CRS103 - Passive Non-Financial Entity that is a CRS Reportable Person'
  }
];

const ACCOUNT_NUMBER_TYPES = [
  { code: 'OECD601', name: 'OECD601 - International bank account number' },
  { code: 'OECD602', name: 'OECD602 - Other bank account number' },
  {
    code: 'OECD603',
    name: 'OECD603 - International securities information number'
  },
  { code: 'OECD604', name: 'OECD604 - Other securities information number' },
  { code: 'OECD605', name: 'OECD605 - Other' }
];

const CONTROLLING_PERSON_TYPES = [
  { code: 'CRS801', name: 'CRS801 - CP of legal person - ownership' },
  { code: 'CRS802', name: 'CRS802 - CP of legal person - other means' },
  {
    code: 'CRS803',
    name: 'CRS803 - CP of legal person - senior managing official'
  },
  {
    code: 'CRS804',
    name: 'CRS804 - CP of legal arrangement - trust - settlor'
  },
  {
    code: 'CRS805',
    name: 'CRS805 - CP of legal arrangement - trust - trustee'
  },
  {
    code: 'CRS806',
    name: 'CRS806 - CP of legal arrangement - trust - protector'
  },
  {
    code: 'CRS807',
    name: 'CRS807 - CP of legal arrangement - trust - beneficiary'
  },
  { code: 'CRS808', name: 'CRS808 - CP of legal arrangement - trust - other' },
  {
    code: 'CRS809',
    name: 'CRS809 - CP of legal arrangement - trust - settlor-equivalent'
  },
  {
    code: 'CRS810',
    name: 'CRS810 - CP of legal arrangement - trust - trustee-equivalent'
  },
  {
    code: 'CRS811',
    name: 'CRS811 - CP of legal arrangement - trust - protector-equivalent'
  },
  {
    code: 'CRS812',
    name: 'CRS812 - CP of legal arrangement - trust - beneficiary-equivalent'
  },
  {
    code: 'CRS813',
    name: 'CRS813 - CP of legal arrangement - trust - other-equivalent'
  }
];

export const state = {
  loading: false,
  countries: [],
  provinces: {},
  currencies: [],
  addressTypes: [],
  documentTypes: [],
  messageTypes: [],

  nameTypes: [],
  accountHolderTypes: [],
  accountNumberTypes: [],
  controllingPersonTypes: []
};

export const mutations = {
  ...make.mutations(state),
  SET_PROVINCES: (state, { key, provinces }) => {
    state.provinces[key] = provinces;
  }
};

export const getters = {
  ...make.getters(state),

  messageTypeMap: state => {
    const map = {};
    state.messageTypes.forEach(el => {
      map[el.code] = el.name;
    });
    return map;
  },

  countryMap: state => {
    const map = {};
    state.countries.forEach(el => {
      map[el.alphaISO2] = el.name;
    });
    return map;
  },

  documentTypeMap: state => {
    const map = {};
    state.documentTypes.forEach(el => {
      map[el.code] = el.name;
    });
    return map;
  },

  nametypeMap: state => {
    const map = {};
    state.nametypes.foreach(el => {
      map[el.code] = el.name;
    });
    return map;
  },

  accountHolderTypeMap: state => {
    const map = {};
    state.accountHolderTypeMap.foreach(el => {
      map[el.code] = el.name;
    });
    return map;
  },

  accountNumberTypeMap: state => {
    const map = {};
    state.accountNumberTypes.foreach(el => {
      map[el.code] = el.name;
    });
    return map;
  }
};

export const actions = {
  init: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      const modules = ['coreModule/fetchCountries'];
      const dispatchChain = [];
      for (const module of modules) {
        dispatchChain.push(dispatch(module, null, { root: true }));
      }

      const promiseQueue = Promise.all(dispatchChain);
      promiseQueue
        .then(([countries]) => {
          commit('SET_COUNTRIES', countries);
          commit(
            'SET_CURRENCIES',
            isoCurrencies.map(el => ({ code: el.cc, name: el.name }))
          );
          commit('SET_ADDRESS_TYPES', ADDRESS_TYPES);
          commit('SET_MESSAGE_TYPES', MESSAGE_TYPE_INDICATORS);
          commit('SET_DOCUMENT_TYPES', DOCUMENT_TYPES);
          commit('SET_ACCOUNT_HOLDER_TYPES', ACCOUNT_HOLDER_TYPES);
          commit('SET_ACCOUNT_NUMBER_TYPES', ACCOUNT_NUMBER_TYPES);
          commit('SET_CONTROLLING_PERSON_TYPES', CONTROLLING_PERSON_TYPES);
          commit('SET_NAME_TYPES', NAME_TYPES);

          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchCountries: async () => {
    try {
      let countries = [];
      for (const countryKey in countriesAndSubDivisions) {
        const province = [];
        for (const key in countriesAndSubDivisions[countryKey].divisions)
          province.push(countriesAndSubDivisions[countryKey].divisions[key]);

        countries.push({
          alphaISO2: countryKey,
          name: countriesAndSubDivisions[countryKey].name,
          provinces: province
        });
      }
      return countries;
    } catch (error) {
      Logger.error('fetching countries', error);
    }
  },

  getCountryProvinces({ state, commit }, { provinceKey, key }) {
    if (!key) return;

    const country = state.countries.find(el => el.alphaISO2.indexOf(key) > -1);
    commit('SET_PROVINCES', {
      key: provinceKey,
      provinces: country.provinces || []
    });

    return country.provinces;
  }
};
